import React from 'react';

import withLightbox from '../Lightbox/settings';

import { BorderRadius } from '../../utils/variables';

import loadComponents from '../Loadable';

const LightboxWrapper = loadComponents('lightbox-wrapper');
const Button = loadComponents('button-hidden');

export default withLightbox(function LoginModal({
  showLightbox,
  openLightbox,
  closeLightbox,
  button,
}) {
  return (
    <>
      <Button onClick={e => openLightbox(e)}>{button}</Button>
      <LightboxWrapper
        showLightbox={showLightbox}
        closeLightbox={closeLightbox}
        noLoader
      >
        <div style={modalInner}>Login</div>
      </LightboxWrapper>
    </>
  );
});

const modalInner = {
  padding: `2.8125rem`,
  backgroundColor: `#fff`,
  borderRadius: BorderRadius,
  width: `420px`,
  maxWidth: `100%`,
  margin: `0 auto`,
  textAlign: `center`,
};
